<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')"  id="car-types" no-body>

    <div class="page-header">
      <b-row>
        <b-col v-if="$checkPermission($options.name, 'insert')"  lg="2">
          <b-button @click="add" v-b-modal.modal-form variant="primary">Ավելացնել</b-button>
        </b-col>
      </b-row>
    </div>

    <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
    >
      <template #cell(group)="data">
        {{ groupName(data.value) }}
      </template>
      <template #cell(actions)="data">
        <fa-icon v-if="$checkPermission($options.name, 'update')"  @click="edit(data.item)" v-b-modal.modal-form icon="edit" class="table-action-icon accent"/>
        <fa-icon v-if="$checkPermission($options.name, 'delete')"  @click="deleteItem(data.item)" icon="trash-alt" class="table-action-icon danger"/>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
          :label="'Գտնվել է ' + totalRows + ' տող'"
          label-cols="3"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
      >
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <!-- modal login-->
    <b-modal
        :no-close-on-backdrop="true"
        id="modal-form"
        cancel-variant="outline-secondary"
        :ok-title="modal_title"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        :title="modal_title"
        @ok="confirmForm"
        :ok-disabled="validateForm"
    >
      <b-form>
        <b-form-group>
          <v-select
              placeholder="Խումբ"
              id="vue-select"
              :reduce="prefix => prefix.id"
              v-model="form.group"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="groups"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
              v-model="form.name"
              id="name"
              type="text"
              placeholder="Տիպ"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
              v-model="form.amount"
              id="amount"
              type="number"
              placeholder="Արժեք"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
              v-model="form.ceo"
              id="ceo"
              type="number"
              placeholder="CEO արժեք"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
              v-model="form.code"
              id="cide"
              type="text"
              placeholder="Կոդ"
          />
        </b-form-group>
      </b-form>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import carsModel from '@/store/cars/cars'
import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  name: 'settings-car-types',
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    vSelect
  },
  data() {
    return {
      CARS_STORE_MODULE_NAME: 'carsModule',
      perPage: 20,
      pageOptions: [this.perPage],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'group',
          label: 'Խումբ',
        },
        {
          key: 'name',
          label: 'Անվանում',
        },
        {
          key: 'amount',
          label: 'Արժեք',
        },
        {
          key: 'code',
          label: 'Կոդ',
        },
        {
          key: 'actions',
          label: 'Կարգավիճակ',
          class: 'text-center width-10-per px-0',
        },
      ],
      addForm: false,
      editForm: false,
      formFields: {
        name: '',
        group: '',
        code: '',
        amount: '',
        ceo: ''
      },
      form: {}
    }
  },
  computed: {

    items() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].car_types
    },

    groups() {
      const result = []
      const data = this.$store.state[this.CARS_STORE_MODULE_NAME].car_groups
      data.map((item) => {
        let temp = {
          id: item.id,
          label: item.name
        }
        result.push(temp)
      })
      return result
    },

    groupName() {
      return (id) => {
        let name = ''
        const index = this.groups.findIndex((i) => i.id === id)
        if (index >= 0) {
          name = this.groups[index].label
        }
        return name
      }
    },

    totalRows() {
      return this.items.length
    },

    modal_title() {
      return (this.addForm) ? 'Ավելացնել' : 'Խմբագրել'
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key
          }))
    },

    validateForm () {
      return this.form.name === '' || this.form.group === '' || this.form.group === null || this.form.code === '' || this.form.amount === ''
    }
  },
  mounted() {
    // Set the initial number of items

  },
  async created() {
    if (!store.hasModule(this.CARS_STORE_MODULE_NAME)) store.registerModule(this.CARS_STORE_MODULE_NAME, carsModel)
    this.getTypes()
    this.getGroups()
  },
  methods: {

    getTypes() {
      this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getTypes')
    },
    getGroups() {
      this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getGroups')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    add() {
      this.editForm = false
      this.addForm = true
      this.form = this.$store.getters['getForm'](true)(this.formFields)(null)
    },
    edit(item) {
      this.addForm = false
      this.editForm = true
      this.form = this.$store.getters['getForm'](false)(this.formFields)(item)
    },
    deleteItem(item) {
      this.$swal({
        title: 'Հաստատե՞լ գործողությունը',
        text: 'Դուք ցանկանում եք հեռացնել տիպը',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Հաստատել',
        cancelButtonText: 'Մերժել',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/deleteType', item.id)
                  .then(() => {
                    this.$swal({
                      icon: 'success',
                      title: 'Հեռացվել է!',
                      text: 'Տիպը հաջողությամբ հեռացվեց',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch((error) => {
                    this.$root.$emit('getError', error)
                  })
            }
          })
    },
    changeStatus(item) {
      const status = !item.status
      const action = (status) ? 'ակտիվացնել' : 'պասիվացնել'
      this.$swal({
        title: 'Հաստատե՞լ գործողությունը',
        text: 'Դուք ցանկանում եք ' + action + ' տիպը',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Հաստատել',
        cancelButtonText: 'Մերժել',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              item.status = status
              this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/editType', item)
                  .then(() => {
                    this.$swal({
                      icon: 'success',
                      title: '',
                      text: 'Փոփոխությունները պահպանվեցին',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch((error) => {
                    this.$root.$emit('getError', error)
                  })
            }
          })
    },
    confirmForm() {
      if (this.addForm) {
        this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/addType', this.form)
            .catch((error) => {
              this.$root.$emit('getError', error)
            })
      } else {
        this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/editType', this.form)
            .catch((error) => {
              this.$root.$emit('getError', error)
            })
      }
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#car-types {
  .table-responsive {
    max-height: calc(var(--vh, 1vh) * 100 - 15rem);
    min-height: calc(var(--vh, 1vh) * 100 - 15rem);
  }
}
</style>


